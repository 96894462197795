.widget-result-heading {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.widget-result-title {
    max-width: 400px;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}

.widget-result-text {
    font-size: 14px;
    margin: 0;
}

.widget-iframe-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    margin-top: 30px;
}

.widget-iframe-container iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 0;
}

@media only screen and (max-width: 1024px) {
    .widget-result-title {
        font-size: 16px;
    }

    .widget-result-text  {
        font-size: 12px;
    }
}
