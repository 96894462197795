.widget-wrapper {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.widget-logo {
    width: 60px;
    margin-bottom: 1rem;
}

.widget-card {
    background-color: #F1F1F3;
    border-radius: 15px;
}

.widget-card-content {
    color: #4A5567;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
}

.widget-button {
    width: 420px;
    background-color: #4A5567;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #FFF;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    padding: 1rem 0;
    margin-top: 1rem;
}

@media only screen and (max-width: 1024px) {
    .widget-wrapper {
        padding: 0.5rem;
    }

    .widget-logo {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: 50px;
    }


    .widget-button {
        width: 380px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 767px) {
    .widget-card-content {
        padding: 1rem;
    }
}

@media only screen and (max-width: 425px) {
    .widget-button {
        width: 90%;
    }
}
