.select-wrapper {
    color: #4A5567;
    display: flex;
    flex-direction: column;
}

.select-label {
    font-size: 24px;
    font-weight: 700;
}

.select-helper-text {
    font-size: 14px;
    margin-top: 0.5rem;
}

.error-message {
    height: 11px;
    font-size: 11px;
    color: red;
    padding-left: 3px;
    margin-top: 0.5rem;
}

@media only screen and (max-width: 1024px) {
    .select-label {
        font-size: 16px;
        white-space: nowrap;
    }

    .select-helper-text {
        font-size: 12px;
    }
}
